import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import getFeeByName from "../../../helpers/getFeeByName";
import getProductDataByName from "../../../helpers/getProductDataByName";

const AccountFeesSpanish = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiProducts {
        nodes {
          Name
          ProductData {
            name
            value
            spanishName
            spanishValue
          }
        }
      }
      allStrapiFees {
        nodes {
          Amount
          Name
          SpanishAmount
          SpanishName
          SpanishNotes
        }
      }
      miscValueDates: allStrapiMiscValues(filter: { Name: { eq: "Bank Fees - Date" } }) {
        nodes {
          Value
        }
      }
    }
  `);

  const allStrapiProducts = data.allStrapiProducts;
  const feesDate = data.miscValueDates.nodes[0].Value;

  const description =
    "Obtenga más información sobre las tarifas y los cargos por servicio asociados con nuestras cuentas de ahorro personales aquí.";
  const SEOData = {
    title: "Cargos de cuentas ahorros personales",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Cargos de cuentas ahorros personales"
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/cargos"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/es",
      title: "Banca Personal"
    },
    {
      id: 2,
      active: true,
      title: "Cargos"
    }
  ];

  /**
   *
   * @param {string} feeName
   * @param {?function} dataTransform
   * @returns {?JSX.Element}
   */
  function renderFeeRow(fontWeightBold, textIndentation, id, feeName, dataTransform = null) {
    let feeRecord = getFeeByName(data.allStrapiFees, feeName);
    if (!feeRecord) {
      return null;
    }
    if (dataTransform && typeof dataTransform === "function") {
      feeRecord = dataTransform(feeRecord);
    }
    return (
      <tr>
        <td
          id={id}
          style={{ width: "66%" }}
          className={`${textIndentation ? "pl-4" : ""} ${fontWeightBold ? "font-weight-bold" : ""}`}
          dangerouslySetInnerHTML={{
            __html: feeRecord.SpanishName + " " + (feeRecord.SpanishNotes === null ? "" : feeRecord.SpanishNotes)
          }}
        />
        <td
          id={`${id}-value`}
          style={{ width: "34%" }}
          dangerouslySetInnerHTML={{ __html: feeRecord.SpanishAmount + (dataTransform === null ? "" : dataTransform) }}
        />
      </tr>
    );
  }

  /**
   *
   * @param {Object} productDataRow
   * @param {?Function} dataTransform
   * @returns {JSX.Element}
   */
  function renderProductDataRow(id, productDataRow, dataTransform = null) {
    if (productDataRow && productDataRow.name !== "") {
      if (typeof dataTransform === "function") {
        productDataRow = dataTransform(productDataRow);
      }
      return (
        <tr>
          <td
            id={id}
            style={{ width: "66%" }}
            className="pl-4"
            dangerouslySetInnerHTML={{ __html: productDataRow.spanishName }}
          />
          <td
            id={`${id}-value`}
            style={{ width: "34%" }}
            dangerouslySetInnerHTML={{ __html: productDataRow.spanishValue }}
          />
        </tr>
      );
    }
  }

  /**
   * @param {string} productName
   * @param {[]} productNamesToInclude
   * @return {[]}
   */
  function renderProductRows(productName, productNamesToInclude = []) {
    const rows = [];
    for (let i = 0; i < productNamesToInclude.length; i++) {
      const productDataRow = getProductDataByName(allStrapiProducts, productName, productNamesToInclude[i]);
      const id = (productName + "-" + productDataRow.name)
        .toLowerCase()
        .replace(/ - +/g, "-")
        .replace(/ +/g, "-")
        .replace("(", "")
        .replace(")", "");
      if (productDataRow !== null) {
        rows.push(renderProductDataRow(id, productDataRow));
      }
    }
    return rows;
  }

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1>Lista de tarifas del consumidor y cargos de servicios</h1>
            <p className="font-weight-bold">
              Efectivo a partir del <span id="account-fees-effective-date">{feesDate}</span>.
            </p>
            <p>
              Todas las tarifas y cargos por servicio están sujetos a cambios. Le enviaremos un aviso por correo 30 días
              antes de que entre en vigencia cualquier aumento en las tarifas o cambios en las tarifas.
            </p>

            <h2 className="text-success font-weight-semi-bold">Cuentas de cheques personales</h2>

            <div className={`table-responsive`}>
              <table className={`table`}>
                <tbody>
                  {/* Free Checking */}
                  <tr className="bg-secondary">
                    <td colSpan="2" id="fc-insuffient-funds">
                      <Link
                        id="free-checking-link"
                        to="/es/banca-personal/gratis-cuenta-de-cheques"
                        className="text-white font-weight-bold"
                      >
                        *Cuenta corriente gratuita
                      </Link>
                    </td>
                  </tr>
                  {renderProductRows("Free Checking", ["Initial Deposit"])}
                  <tr>
                    <td colSpan="2" className="pl-4">
                      <em>Sin Cargo por Servicio de Saldo Mínimo</em>
                    </td>
                  </tr>

                  {/* Rewards Checking */}
                  <tr className="bg-secondary">
                    <td colSpan="2" id="account-fees-rewards-checking">
                      <Link
                        id="rewards-checking-link"
                        to="/es/banca-personal/rewards-checking"
                        className="text-white font-weight-bold"
                      >
                        Cuenta Corriente de Recompensas
                      </Link>
                    </td>
                  </tr>
                  {renderProductRows("Rewards Checking", ["Initial Deposit"])}
                  <tr>
                    <td colSpan="2" className="pl-4">
                      <em id="rewards-checking-disclaimer">Incluye recompensas</em>
                    </td>
                  </tr>
                  {renderProductRows("Rewards Checking", ["Service Charge"])}

                  {/* Premium Rewards Checking */}
                  <tr className="bg-secondary">
                    <td colSpan="2" id="account-fees-premium-rewards-checking">
                      <Link
                        id="premium-rewards-checking-link"
                        to="/es/banca-personal/rewards-checking"
                        className="text-white font-weight-bold"
                      >
                        Cuenta corriente premium de recompensas
                      </Link>
                    </td>
                  </tr>
                  {renderProductRows("Premium Rewards Checking", ["Initial Deposit"])}
                  <tr>
                    <td colSpan="2" className="pl-4">
                      <em id="premium-rewards-checking-disclaimer">Incluye recompensas premium</em>
                    </td>
                  </tr>
                  {renderProductRows("Premium Rewards Checking", ["Service Charge"])}

                  {/* Interest Checking */}
                  <tr className="bg-secondary">
                    <td colSpan="2" id="account-fees-fresh-start">
                      <Link
                        id="interest-checking-link"
                        to="/es/banca-personal/interest-rewards-checking"
                        className="text-white font-weight-bold"
                      >
                        Interest Checking<sup>&dagger;</sup>
                      </Link>
                    </td>
                  </tr>
                  {renderProductRows("Interest Checking", ["Initial Deposit"])}

                  <tr>
                    <td colSpan="2" id="interest-checking-disclaimer-1" className="pl-4">
                      <em>Incluye premium de recompensas</em>
                    </td>
                  </tr>
                  {renderProductRows("Interest Checking", [
                    "Minimum Balance to Waive Service Charge",
                    "Service Charge"
                  ])}

                  <tr>
                    <td colSpan="2" id="interest-checking-disclaimer-2" className="pl-4">
                      Sin saldo mínimo para ganar interés
                    </td>
                  </tr>

                  <tr className="bg-secondary">
                    <td colSpan="2" id="account-fees-fresh-start">
                      <Link
                        id="fresh-start-link"
                        to="/es/banca-personal/cuenta-nuevo-comienzo"
                        className="text-white font-weight-bold"
                      >
                        Cuenta Nuevo Comienzo (Fresh Start)
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="pl-4">
                      <em id="fresh-start-disclaimer">No apto para cheques o cheques iniciales</em>
                    </td>
                  </tr>

                  {renderProductRows("Fresh Start (Bank On Certified)", ["Initial Deposit", "Service Charge"])}
                </tbody>
              </table>
            </div>

            <h2 className="text-success font-weight-semi-bold">Cuentas de ahorro y de money market</h2>

            <div className={`table-responsive`}>
              <table className={`table`}>
                <tbody>
                  {/* Money Market */}
                  <tr className="bg-secondary">
                    <td colSpan="2">
                      <Link
                        id="money-market-link"
                        to="/es/banca-personal/cuentas-de-ahorro#savings-overview-section"
                        className="text-white font-weight-bold"
                      >
                        Mercado Monetario<sup>&dagger;</sup>
                      </Link>
                    </td>
                  </tr>
                  {renderProductRows("Money Market", [
                    "Initial Deposit",
                    "Minimum Balance to Waive Service Charge",
                    "Service Charge",
                    "Minimum Balance to Earn Interest"
                  ])}

                  {/* Gold Money Market */}
                  <tr className="bg-secondary">
                    <td colSpan="2">
                      <Link
                        id="gold-money-market-link"
                        to="/es/banca-personal/cuentas-de-ahorro#savings-overview-section"
                        className="text-white font-weight-bold"
                      >
                        Mercado monetario de oro<sup>&dagger;</sup>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" id="gold-money-market-disclaimer-1" className="pl-4">
                      <em>La promoción puede ser discontinuada en cualquier momento.</em>
                    </td>
                  </tr>
                  {renderProductRows("Gold Money Market", [
                    "Initial Deposit",
                    "Minimum Balance to Waive Service Charge",
                    "Service Charge",
                    "Minimum Balance to Earn Interest"
                  ])}

                  {/* High Yield Money Market */}
                  <tr className="bg-secondary">
                    <td colSpan="2">
                      <Link
                        id="high-yield-money-market-link"
                        to="/es/banca-personal/cuentas-de-ahorro#savings-overview-section"
                        className="text-white font-weight-bold"
                      >
                        Cuentas Money Market de Interés Alto<sup>&dagger;</sup>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" id="high-yield-money-market-disclaimer-1" className="pl-4">
                      <em>La promoción puede ser discontinuada en cualquier momento.</em>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" id="high-yield-money-market-disclaimer-2" className="pl-4">
                      <em>No disponible para IRAs o clientes comerciales.</em>
                    </td>
                  </tr>
                  {renderProductRows("High Yield Money Market", [
                    "Initial Deposit",
                    "Minimum Balance to Waive Service Charge",
                    "Service Charge"
                  ])}

                  {/* Savings */}
                  <tr className="bg-secondary">
                    <td colSpan="2">
                      <Link
                        id="savings-link"
                        to="/es/banca-personal/cuentas-de-ahorro"
                        className="text-white font-weight-bold"
                      >
                        Cuenta de ahorro<sup>&dagger;</sup>
                      </Link>
                    </td>
                  </tr>
                  {renderProductRows("Savings", [
                    "Initial Deposit",
                    "Minimum Balance to Waive Service Charge",
                    "Service Charge",
                    "Minimum Balance to Earn Interest"
                  ])}

                  {/* Start Savings */}
                  <tr className="bg-secondary">
                    <td colSpan="2" className="text-white font-weight-bold">
                      Comenzar a ahorrar<sup>&dagger;</sup>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" id="start-savings-disclaimer-1" className="pl-4">
                      <em>Requiere una cuenta correinte para abrir.</em>
                    </td>
                  </tr>
                  {renderProductRows("Start Savings", ["Initial Deposit"])}
                  <tr>
                    <td id="start-savings-disclaimer-2" colSpan="2" className="pl-4">
                      <em>Sin cargo por servicio mínimo de saldo</em>
                    </td>
                  </tr>
                  <tr>
                    <td id="start-savings-disclaimer-3" colSpan="2" className="pl-4">
                      <em>Sin saldo mínimo para ganar interés</em>
                    </td>
                  </tr>

                  {/* Minor Savings */}
                  <tr className="bg-secondary">
                    <td colSpan="2">
                      <Link
                        id="minor-savings-link"
                        to="/es/banca-personal/cuentas-de-ahorro"
                        className="text-white font-weight-bold"
                      >
                        Cuenta de ahorro para menores<sup>&dagger;</sup>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" id="minor-savings-disclaimer" className="pl-4">
                      <em>Sin saldo mínimo Cargo por servicio (hasta los 18 años)</em>
                    </td>
                  </tr>
                  {renderProductRows("Minor Savings", ["Initial Deposit", "Minimum Balance to Earn Interest"])}

                  {/* Save at School */}
                  <tr className="bg-secondary">
                    <td colSpan="2">
                      <Link
                        id="minor-savings-link"
                        to="/personal-banking/savings-account/youth-savings-account/save-at-school"
                        className="text-white font-weight-bold"
                      >
                        Ahorrar en cuentas escolares<sup>&dagger;</sup>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" id="save-at-school-disclaimer" className="pl-4">
                      <em>
                        Disponible únicamente para menores en edad escolar primaria que formen parte de un programa
                        activo de Ahorro en la Escuela
                      </em>
                    </td>
                  </tr>
                  {renderProductRows("Save at School Savings", [
                    "Initial Deposit",
                    "Minimum Balance to Waive Service Charge"
                  ])}
                </tbody>
              </table>
            </div>

            <h2 className="text-success font-weight-semi-bold">Pagos en persona</h2>
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr className="bg-secondary text-white">
                    <td colSpan="2">
                      <strong>Cheque de cajero</strong>
                    </td>
                  </tr>
                  {/* update in Strapi */}
                  {renderFeeRow(false, true, "cashiers-checks-clients-only", "Cashier's Checks")}
                  <tr className="bg-secondary text-white">
                    <td colSpan="2">
                      <strong>Cobros y procesamiento de cheques extranjeros</strong>
                    </td>
                  </tr>
                  {renderFeeRow(false, true, "foreign-all-check-collections", "All Check Collections")}
                  {renderFeeRow(false, true, "canadian-check-processing", "Canadian Check Processing")}
                  {renderFeeRow(
                    false,
                    true,
                    "all-other-foreign-check-processing",
                    "All Other Foreign Check Processing"
                  )}

                  <tr className="bg-secondary text-white">
                    <td colSpan="2">
                      <strong>Transferencias externas</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="pl-4">
                      <em>Debe programarse antes de las 9:30 a. m. PT o garantía de mismo día.</em>
                    </td>
                  </tr>
                  {/* update in Strapi */}
                  {renderFeeRow(false, true, "in-branch-external-transfers", "Pre-Authorized Transaction - Same Day")}

                  <tr className="bg-secondary">
                    <td colSpan="2" className="text-white">
                      <strong>Transferencias Bancarias</strong>
                    </td>
                  </tr>
                  {renderFeeRow(false, true, "incoming-wire-transfers", "Incoming")}
                  {renderFeeRow(false, true, "outgoing-wire-transfers", "Outgoing—Domestic")}
                  {renderFeeRow(false, true, "outgoing-wire-transfers-international", "Outgoing—International")}
                </tbody>
              </table>
            </div>

            <h2 className="text-success font-weight-semi-bold">Otros cargos y servicios</h2>

            <div className={`table-responsive`}>
              <table className={`table`}>
                <tbody>
                  {renderFeeRow(true, false, "card-replacement-order-service", "Card Replacement—Standard Service")}
                  {renderFeeRow(
                    true,
                    false,
                    "card-replacement-expedited-service",
                    "Card Replacement—Expedited Service"
                  )}
                  {renderFeeRow(true, false, "check-images-monthly-statement", "Check Images with Monthly Statement")}
                  {renderFeeRow(true, false, "inactive-account", "Inactive Account Fee—Checking only")}
                  {renderFeeRow(true, false, "legal-processing", "Legal Processing")}
                  {renderFeeRow(true, false, "loan-payment-returned-check-fee", "Loan Payment—Returned Check Fee")}
                  {renderFeeRow(true, false, "nonsufficient-funds-fee", "Nonsufficient Funds Fee (NSF)")}
                  {renderFeeRow(true, false, "nsf-daily-maximum", "NSF Daily Maximum")}
                  {renderFeeRow(true, false, "research-fee", "Research Fee (1 Hour Minimum)")}
                  <tr>
                    <td id="box-availability" colSpan="2">
                      <strong>Disponibilidad de cajas**</strong>
                      <br />
                      <em>
                        La disponibilidad y el tamaño de la caja de seguridad dependen de la sucursal. Pídale una
                        cotización a un{" "}
                        <Link
                          to="https://www.wafdbank.com/es/sucursales#branch-search-section"
                          id="sdb-branch-search-link"
                        >
                          representante de la sucursal
                        </Link>
                        .
                      </em>
                    </td>
                  </tr>
                  {renderFeeRow(true, false, "safe-deposit-box-key-deposit", "Safe Deposit Box Key Deposit")}
                  {renderFeeRow(true, "safe-deposit-box-drilling", "Safe Deposit Box Drilling")}

                  {renderFeeRow(true, false, "stop-payment", "Stop Payment")}
                  {renderFeeRow(
                    true,
                    false,
                    "visa-international-transaction-fee",
                    "VISA International Transaction Fee"
                  )}
                </tbody>
              </table>
            </div>

            <p id="free-checking-disclaimer" className="text-muted text-sm">
              *Pueden aplicar cargos por fondos insufientes.
            </p>
            <p id="safe-deposit-box-disclaimer" className="text-muted text-sm">
              ***Calendario de renta anual para el depósito de llave para caja fuerte disponible hasta que sea
              solicitada
            </p>
            <p id="savings-disclaimer" className="text-muted text-sm">
              <sup>&dagger;</sup>El interés se calcula diariamente y se paga mensualmente.
            </p>
            <p id="research-fee-disclaimer" className="text-muted text-sm mb-0">
              <sup>&Dagger;</sup>Evaluado solo si la solicitud tomará al menos una hora. Incluye costo por estado de
              cuenta, ticket de depósito y/o copias de cheques.
            </p>
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default AccountFeesSpanish;
